import React from 'react';

export const CompareImage = () => (
  <svg viewBox="0 0 331.77 335.65">
    <defs>
      <style>{'.prefix__cls-1-compare{fill:#dde1fd}.prefix__cls-3-compare{fill:#fff}'}</style>
    </defs>
    <title>{'Zas\xF3b 32'}</title>
    <g id="prefix__Warstwa_2" data-name="Warstwa 2">
      <g id="prefix__Warstwa_1-2" data-name="Warstwa 1">
        <ellipse
          className="prefix__cls-1-compare"
          cx={217.17}
          cy={20.22}
          rx={20.25}
          ry={20.2}
          transform="rotate(-27.09 217.135 20.227)"
        />
        <path
          className="prefix__cls-1-compare"
          d="M234.68 103.21c-56.38-40.89-43.49-85.1-80.08-95.6C104.32-6.83 45.64 66.84 41.21 72.5c-10.47 13.33-53.68 69.58-37.7 139.33 17.8 77.75 105.2 108.16 119 112.72 48.75 16.08 128.91 20.8 175.3-28.81 3.47-3.71 42.5-46.66 32.21-96.07-3.9-18.78-13.57-31.93-20.72-41.45-23.51-31.37-46.39-34.53-74.62-55.01z"
        />
        <path
          transform="rotate(-2.22 180.794 275.339)"
          fill="#ff5842"
          d="M126.31 268.83H235.9v14.03H126.31z"
        />
        <path
          className="prefix__cls-3-compare"
          d="M135 268.83s12-26.06 46.11-24.73 47.44 24.73 47.44 24.73"
        />
        <path d="M135 269.33a.54.54 0 01-.21-.05.5.5 0 01-.25-.66c.12-.27 12.56-26.36 46.59-25s47.72 24.74 47.86 25a.5.5 0 01-.87.5c-.13-.23-13.64-23.17-47-24.48s-45.55 24.14-45.67 24.36a.49.49 0 01-.45.33z" />
        <path d="M177.77 256c-.28 0-.5-.3-.5-.66l1.34-174.9a.61.61 0 01.51-.65.59.59 0 01.49.66l-1.33 174.91c-.02.36-.28.64-.51.64zM168.41 256c-.27 0-.48-.44-.46-.92l4-96.31c0-.47.27-.8.54-.79s.48.44.46.92l-4 96.3c-.06.47-.28.8-.54.8zM195.14 171a.51.51 0 01-.5-.44L181.28 69.68a.51.51 0 01.43-.57.5.5 0 01.56.43l13.37 100.91a.5.5 0 01-.43.56z" />
        <path
          className="prefix__cls-3-compare"
          d="M96.93 107.3a53.92 53.92 0 01-12.78-1.53 2.84 2.84 0 111.46-5.49c.26.06 18.75 4.8 28.8-4.07 9-8 19.3-17 31-24.37 12.05-7.57 27.87-11 44.54-9.68 13.6 1.09 28 5.32 42.7 12.59 2.7 1.33 5.45 3 8.11 4.53 5.57 3.29 11.32 6.69 17 7.69 9 1.6 16.93 1.37 23.45-.69a2.84 2.84 0 011.71 5.42c-7.4 2.34-16.2 2.63-26.15.87-6.66-1.18-12.87-4.85-18.87-8.39-2.69-1.59-5.23-3.1-7.74-4.33-14.09-6.95-27.76-11-40.63-12-15.46-1.24-30 1.9-41.06 8.82-11.29 7.1-21.37 16-30.25 23.83-6.22 5.41-14.46 6.8-21.29 6.8z"
        />
        <path d="M96.93 107.63a54 54 0 01-12.87-1.54 3.18 3.18 0 011.63-6.09c.19 0 18.61 4.74 28.5-4 9.06-8 19.32-17 31-24.4 12.11-7.61 28-11.07 44.74-9.73 13.63 1.09 28 5.33 42.82 12.62 2.71 1.34 5.47 3 8.13 4.55 5.54 3.27 11.27 6.65 16.86 7.64 9 1.59 16.82 1.36 23.29-.68a3.18 3.18 0 012.42.21A3.13 3.13 0 01285 88a3.18 3.18 0 01-2 4c-7.45 2.35-16.3 2.65-26.31.87-6.72-1.18-13-4.87-19-8.43-2.68-1.58-5.22-3.08-7.71-4.31-14.06-6.93-27.69-11-40.51-12-15.39-1.22-29.9 1.89-40.87 8.78-11.27 7.08-21.33 16-30.2 23.79-5.21 4.61-12.4 6.93-21.47 6.93zm-12.05-7.11a2.51 2.51 0 00-.65 4.93 53.81 53.81 0 0012.7 1.55c8.88 0 15.95-2.27 21-6.74 8.89-7.85 19-16.75 30.29-23.86 11.08-7 25.74-10.12 41.27-8.88 12.91 1 26.62 5.09 40.75 12.06 2.52 1.24 5.07 2.75 7.76 4.34 6 3.53 12.15 7.18 18.76 8.35 9.9 1.75 18.64 1.46 26-.86a2.51 2.51 0 00-1.51-4.78c-6.57 2.07-14.51 2.31-23.61.7-5.71-1-11.49-4.43-17.08-7.73-2.65-1.57-5.4-3.19-8.09-4.52-14.7-7.25-29-11.47-42.57-12.56-16.6-1.32-32.35 2.1-44.34 9.63-11.65 7.33-21.9 16.36-30.93 24.34-10.17 9-28.93 4.19-29.11 4.14a2.41 2.41 0 00-.64-.11zM135 188.89a30.41 30.41 0 11-60.81 0M310.75 173.52a30.41 30.41 0 11-60.81 0" />
        <path d="M131.66 191.07a.52.52 0 01-.47-.33l-32.52-84.65-21.32 82.59a.51.51 0 01-1-.25l21.72-84.11a.51.51 0 011 0l33.07 86.11a.51.51 0 01-.28.65.58.58 0 01-.2-.01zM309.08 174a.5.5 0 01-.47-.31l-32.18-79.46-21.67 79.43a.5.5 0 11-1-.27l22.05-80.86a.53.53 0 01.45-.37.51.51 0 01.5.32l32.74 80.86a.5.5 0 01-.27.65zM112.61 112.55a.5.5 0 01-.5-.48.51.51 0 01.48-.53c.13 0 13.84-.8 20.3-12.44a.49.49 0 01.68-.19.51.51 0 01.2.68c-6.74 12.13-21 13-21.13 13zM268.59 84.12a43 43 0 01-22.74-6.72.5.5 0 01-.12-.7.51.51 0 01.7-.12c.13.1 13.91 9.71 31.1 5.45a.5.5 0 01.61.37.5.5 0 01-.37.6 37.73 37.73 0 01-9.18 1.12z" />
        <path d="M93.9 194.74a.5.5 0 01-.47-.53l3.34-64.82a.5.5 0 011 .06l-3.37 64.82a.5.5 0 01-.5.47z" />
        <path d="M82.54 185.39a.51.51 0 01-.3-.91c.19-.14 19.9-14.76 38.7 0a.49.49 0 01.08.7.5.5 0 01-.7.09c-18.19-14.3-37.29-.14-37.48 0a.48.48 0 01-.3.12z" />
        <circle className="prefix__cls-3-compare" cx={181.11} cy={71.62} r={17.37} />
        <path d="M181.11 89.49A17.88 17.88 0 11199 71.62a17.9 17.9 0 01-17.89 17.87zm0-34.75A16.88 16.88 0 10198 71.62a16.9 16.9 0 00-16.89-16.88z" />
        <ellipse
          className="prefix__cls-1-compare"
          cx={250.21}
          cy={45.72}
          rx={9.49}
          ry={9.46}
          transform="rotate(-27.09 250.187 45.735)"
        />
      </g>
    </g>
  </svg>
);
