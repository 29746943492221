/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/Layout';
import { Hero } from '../components/Hero';
import { SliderLogos } from '../components/SliderLogos';
import { HowToFindInsuranceImage } from '../img/svg/HowToFindInsuranceImage';
import { HowToSaveImage } from '../img/svg/HowToSaveImage';
import { HowToChooseInsurerImage } from '../img/svg/HowToChooseInsurerImage';
import { AboutUsEarth } from '../img/svg/AboutUsEarth';
import { AdvicesImage } from '../img/svg/AdvicesImage';
import { ThinkingImage } from '../img/svg/ThinkingImage';
import { CompareImage } from '../img/svg/CompareImage';
import { PostList } from '../components/PostList/PostList';
import { INodePostSummary } from '../interfaces/blog';

import compass from '../img/icons/icon-compass.svg';
import wardrobe from '../img/icons/icon-wardrobe.svg';
import refresh from '../img/icons/icon-refresh.svg';
import add from '../img/icons/icon-add.svg';
import dolar from '../img/icons/icon-dolar.svg';
import usage from '../img/icons/icon-usage.svg';
import star from '../img/icons/icon-star.svg';
import find from '../img/icons/icon-find.svg';
import car from '../img/icons/icon-car.svg';
import shield from '../img/icons/icon-shield.svg';
import forward from '../img/icons/icon-forward.svg';

const TempContent = ({ posts }: { posts: INodePostSummary[]}) => (
  <>
    <Helmet title="Ubezpieczenia | Bezpłatna porównywarka ubezpieczeń | swiatubezpieczen.com" />
    <section id="hero" className="section section--reset">
      <Hero
        button="Porównaj ceny"
      />
    </section>
    <section id="partners" className="section section--partners">
      <div className="wrapper">
        <div className="partners-list">
          <div className="partners-list__wrapper">
            <SliderLogos />
            <SliderLogos />
          </div>
        </div>
      </div>
    </section>
    <section id="about" className="section section--lightblue">
      <div className="wrapper wrapper--narrower">
        <div className="row">
          <div className="row__large-6">
            <p className="paragraph paragraph--strong">Świat Ubezpieczeń to nowoczesna firma, która gromadzi zespół najlepszych specjalistów z branży ubezpieczeniowej.</p>
          </div>
          <div className="row__large-6">
            {/* eslint-disable-next-line max-len */}
            <p>Misją Świata Ubezpieczeń jest przekazywanie możliwie najbardziej rzetelnych informacji na temat ubezpieczeń. Dowiesz się tutaj między innymi, jak wybrać najlepsze ubezpieczenie i dlaczego nie zawsze warto kierować się ceną. Możesz poznać ofertę poszczególnych towarzystw ubezpieczeniowych w Polsce – dzięki temu łatwiej będzie Ci wybrać to z nich, które najlepiej spełnią Twoje oczekiwania. </p>
          </div>
        </div>
      </div>
    </section>
    <section id="cards" className="section section--cards">
      <div className="wrapper">
        <div className="row">
          <div className="row__large-4">
            <div className="card">
              <div className="card__image">
                <ThinkingImage />
              </div>
              <p className="card__content">Zastanawiasz się, które towarzystwo ubezpieczeniowe wybrać? Mamy kilka sposobów, które pomogą Ci wybrać ofertę dostosowaną do oczekiwań. </p>
              <Link className="card__link" to="/jak-wybrac-towarzystwo-ubezpieczeniowe/">Czytaj więcej ⟶ </Link>
            </div>
          </div>
          <div className="row__large-4">
            <div className="card">
              <div className="card__image">
                <CompareImage />
              </div>
              <p className="card__content">Porównaj oferty ubezpieczniowe w 5 prostych krokach, korzystając z naszej porównywarki.</p>
              <a className="card__link" rel="noopener noreferrer" target="_blank" href="https://porowneo.pl/ubezpieczenia/samochodu/ubezpieczenie-oc">Porównaj</a>
            </div>
          </div>
          <div className="row__large-4">
            <div className="card">
              <div className="card__image">
                <AdvicesImage />
              </div>
              <p className="card__content">Świat Ubezpieczeń to także aktualne newsy, porady i informacje dotyczące ubezpieczeń. Wszystkiego dowiesz się na naszym blogu!</p>
              <Link className="card__link" to="/blog/">Zobacz więcej</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="find" className="section section--lightblue section--find">
      <div className="wrapper">
        <div className="row row--col-reverse row--flex">
          <div className="row__large-6 row__large--offset-1">
            <span className="heading-short heading-short--extra-padding">Dowiedz się!</span>
            <h2 className="heading">Jak znaleźć ubezpieczenie dla siebie? </h2>
            <p className="paragraph">Podjąłeś decyzję o zakupie ubezpieczenia, jednak nie wiesz, jaka polisa będzie najlepsza dla Ciebie? Takie pytanie pojawia się w głowie większości osób, które nigdy nie korzystały z dodatkowego ubezpieczenia. Na szczęście w Świecie Ubezpieczeń możesz liczyć na wyczerpujące porady na temat dostępnych polis czy ofert poszczególnych towarzystw ubezpieczeniowych. </p>
            <p className="heading-inner heading-inner--blue heading-inner--icon">
              <img
                className="heading-inner__icon"
                src={compass}
                alt="Ikona szafki"
              />
              Zastanów się jakiego ubezpieczenia potrzebujesz
            </p>
            <p className="paragraph paragraph--blue-bottom-border">Wybór jest spory – ubezpieczenie na życie, OC w życiu prywatnym, ubezpieczenie domu lub mieszkania, NNW i wiele innych. Jeśli często podróżujesz, z pewnością sprawdzi się polisa turystyczna. A może codziennie spędzasz wiele czasu za kierownicą i chciałbyś cieszyć się większą ochroną, a nie tylko obowiązkowym OC? Kiedy już podejmiesz decyzję na temat tego, jaka polisa jest Ci potrzebna, znalezienie najlepszej oferty będzie o wiele łatwiejsze. </p>
            <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
              <img
                className="heading-inner__icon"
                src={wardrobe}
                alt="Ikona szafki"
              />
              Wnikliwie analizuj oferty
            </p>
          </div>
          <div className="row__large-5">
            <div className="image-wrapper image-wrapper--right image-wrapper--find">
              <HowToFindInsuranceImage />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="save" className="section section--save">
      <div className="wrapper">
        <div className="row">
          <div className="row__large-5 row__large--offset-1">
            <div className="image-wrapper image-wrapper--left image-wrapper--save">
              <HowToSaveImage />
            </div>
          </div>
          <div className="row__large-6">
            <span className="heading-short heading-short--extra-padding">Dowiedz się!</span>
            <h2 className="heading heading--find">Jak oszczędzić ?</h2>
            <p className="paragraph">Chcesz cieszyć się pełną ochroną, jednak nie masz ochoty wydawać zbyt dużej kwoty? Na szczęściej istnieje kilka sposobów na to, aby oszczędzić na ubezpieczeniu.</p>
            <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
              <img
                className="heading-inner__icon"
                src={refresh}
                alt="Ikona szafki"
              />
              Porównuj i jeszcze raz porównuj!
            </p>
            <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
              <img
                className="heading-inner__icon"
                src={add}
                alt="Ikona szafki"
              />
              Wybieraj pakiety
            </p>
            <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
              <img
                className="heading-inner__icon"
                src={dolar}
                alt="Ikona szafki"
              />
              Cena ma znaczenie, ale …
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="choose" className="section section--lightblue section--choose">
      <div className="wrapper">
        <div className="row row--col-reverse row--flex">
          <div className="row__large-6 row__large--offset-1">
            <span className="heading-short heading-short--extra-padding">Dowiedz się!</span>
            <h2 className="heading heading--mobile">Jak wybrać towarzystwo ubezpieczeniowe?</h2>
            <p className="paragraph">Zastanawiasz się, które towarzystwo ubezpieczeniowe wybrać? Na polskim rynku ubezpieczeń istnieje wiele firm, które kuszą klientów zarówno bardzo korzystną ceną, jak i szerokim zakresem ochrony. W efekcie podjęcie decyzji może być bardzo trudne. Na szczęście istnieje kilka sposobów, które pomogą Ci wybrać towarzystwo ubezpieczeniowe, które najlepiej spełni Twoje oczekiwania. </p>
            <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
              <img
                className="heading-inner__icon"
                src={usage}
                alt="Ikona szafki"
              />
              Sprecyzuj oczekiwania
            </p>
            <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
              <img
                className="heading-inner__icon"
                src={star}
                alt="Ikona szafki"
              />
              Wybieraj zaufane firmy
            </p>
            <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
              <img
                className="heading-inner__icon"
                src={find}
                alt="Ikona szafki"
              />
              Poznaj zakres ubezpieczenia i warunki umowy
            </p>
            <p className="paragraph paragraph--top-padding">Jak widzisz, wybór towarzystwa ubezpieczeniowego nie musi być trudny. Wystarczy, że dokładnie określisz swoje oczekiwania i poszukasz zaufanej firmy, która oferuje jasne warunki. Eksperci Świata Ubezpieczeń pomogą Ci w znalezieniu oferty, któ ra spełni Twoje oczekiwania. </p>
            <Link className="read-more" to="/jak-wybrac-towarzystwo-ubezpieczeniowe/">Czytaj więcej ⟶ </Link>
          </div>
          <div className="row__large-5">
            <div className="image-wrapper image-wrapper--right image-wrapper--choose">
              <HowToChooseInsurerImage />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="blog" className="section section--blog">
      <div className="wrapper">
        <PostList posts={posts} title="Newsy ze świata ubezpieczeń" />
      </div>
    </section>
    <section id="about-insurance" className="section section--lightblue section--about-insurance">
      <div className="wrapper">
        <div className="row row--gutters">
          <div className="row__large-6">

            <span className="heading-short">Podstawa bezpiecznej jazdy</span>
            <h2 className="heading heading--about-insurance">Ubezpieczenie komunikacyjne</h2>
          </div>
        </div>
        <div className="row row--gutters ">
          <div className="row__large-6">
            <h3 className="heading-sub">Obowiązkowe ubezpieczenie OC</h3>
            <p className="paragraph">Ubezpieczenie OC jest w Polsce obowiązkowe. Jego zakres regulowany jest przez Ustawę o ubezpieczeniach obowiązkowych, Ubezpieczeniowy Fundusz Gwarancyjny i Polskie Biuro Ubezpieczycieli Komunikacyjnym. Według ustawy, pochodzącej z 22 maja 2003 roku, każdy właściciel pojazdu mechanicznego zobowiązany jest do posiadania ubezpieczenia OC. W związku z tym każdy kierowca w Polsce miał kiedyś do czynienia z OC. </p>
            <h3 className="heading-sub">OC to nie wszystko</h3>
            <p className="paragraph">Najczęściej poszukujemy taniego OC – to zrozumiałe, ponieważ w każdym towarzystwie ubezpieczeniowym jego zakres jest taki sam. Niestety, OC stanowi ochronę nie dla jego posiadacza, ale dla innych użytkowników drogi. Kiedy dojdzie do stłuczki, odszkodowanie zostanie wypłacone z OC sprawcy. Jeśli natomiast nasze auto zostanie uszkodzone przez dzikie zwierzę czy grad, będziemy musieli zapłacić za naprawę z własnej kieszeni. Z tego powodu warto rozważyć zakup dodatkowego ubezpieczenia – w końcu OC to nie wszystko. </p>
          </div>
          <div className="row__large-6">
            <h3 className="heading-sub">Dlaczego warto rozważyć Autocasco ?</h3>
            <p className="paragraph">Coraz więcej kierowców decyduje się na zakup Autocasco. To ubezpieczenie nie jest obowiązkowe, jednak może okazać się niezastąpione. AC zadziała w różnych sytuacjach – na przykład kiedy samochód zostanie skradziony lub uszkodzony z winy kierującego. Choć ceny AC są z reguły wyższe niż koszt OC, można skorzystać z naprawdę korzystnych ofert. Przykładem są pakiety na różne rodzaje ubezpieczenia. Towarzystwa ubezpieczeniowe często oferują duże rabaty osobom, które decydują się na zakup OC i AC. </p>
            <p className="paragraph">Zastanawiasz się, jakie Autocasco będzie najlepsze dla Ciebie? Oczywiście najważniejszy jest jego zakres – im szersza ochrona, tym lepiej. W przypadku AC nie zawsze warto kierować się tylko ceną. Czasem warto dopłacić, aby zabezpieczyć się na wypadek większej ilości sytuacji. Autocasco sprawdzi się w szczególności w przypadku nowych aut lub takich, w których części zamienne są bardzo drogie. Oprócz tego osoby, które mieszkają w dużych miastach, również powinny rozważyć zakup AC – w większym mieście łatwiej o stłuczkę, dlatego warto zapewnić sobie ochronę. </p>
          </div>
        </div>
        <article className="wrapper wrapper--about-insurance wrapper--border-top">
          <div className="row row--gutters">
            <div className="row__large-6">
              <h3 className="heading-sub heading-sub--in-article">Rodzaje ubezpieczenia komunikacyjnego</h3>
              <p className="paragraph">Doskonale wiesz, jak działa obowiązkowe ubezpieczenie komunikacyjne OC, ale nie masz pewności, na czym dokładnie polega Autocasco, NNW czy Assistance? Specjalnie dla Ciebie przygotowaliśmy krótkie informacje, dzięki którym dowiesz się, jaki zakres ochrony jest gwarantowany w ramach każdego z tych typów ubezpieczenia samochodu. </p>
            </div>
            <div className="row__large-6">
              <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
                <img
                  className="heading-inner__icon"
                  src={car}
                  alt="Ikona szafki"
                />
                Autocasco
              </p>
              <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
                <img
                  className="heading-inner__icon"
                  src={shield}
                  alt="Ikona szafki"
                />
                NNW
              </p>
              <p className="heading-inner heading-inner--icon heading-inner--bottom-border">
                <img
                  className="heading-inner__icon"
                  src={forward}
                  alt="Ikona szafki"
                />
                Assistance
              </p>
            </div>
          </div>
        </article>
      </div>
    </section>
    <section id="about-company" className="section section--about-company">
      <div className="wrapper">
        <div className="row row-gutters">
          <div className="row__large-6">
            <div className="image-wrapper image-wrapper--about-company">
              <AboutUsEarth />
            </div>
          </div>
          <div className="row__large-6 row__medium-8 row__medium--offset-left-2 row__large--offset-left-0">
            <p className="paragraph">
              {/* eslint-disable-next-line max-len */}
              Świat Ubezpieczeń powstał z myślą o wszystkich, którzy chcą zapewnić ochronę sobie i swoim najbliższym. Odpowiednie ubezpieczenie zabezpieczy Cię na wypadek nieprzewidzianych okoliczności – choroby czy wypadku komunikacyjnego. W Świecie Ubezpieczeń skupiamy się przede wszystkim na ubezpieczeniach komunikacyjnych – to właśnie one najbardziej interesują wszystkich kierowców w Polsce. Dołącz do Świata Ubezpieczeń i ciesz się pełną ochroną w korzystnej cenie.
            </p>
            <p className="paragraph paragraph--top-border">
              O nas. &nbsp;
              <Link className="read-more" to="/o-nas/">Czytaj więcej ⟶ </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </>

);

interface HomePageData {
  data: {
    posts: {
      edges: INodePostSummary[];
    };
  };
  path: string;
}

// const Index: React.FC<HomePageData> = ({ data: { wordpressPage: { content, title } } }) => (
const Index: React.FC<HomePageData> = ({ data: { posts: { edges: posts } } }) => (
  <Layout>
    <Helmet>
      <title>Ubezpieczenia | Bezpłatna porównywarka ubezpieczeń | swiatubezpieczen.com</title>
      <meta name="description" content="Witaj w Świecie Ubezpieczeń! To nie tylko nowoczesna i wygodna porównywarka polis, ale też źródło najbardziej rzetelnej wiedzy na temat ubezpieczeń. Znajdź polisę, dopasowaną do swoich potrzeb i zapewnij sobie najlepszą ochronę." />
    </Helmet>

    <main
      className="content"
    >
      <TempContent posts={posts} />
    </main>

  </Layout>
);

export const query = graphql`
    query HomesiteQuery {
        posts: allWordpressPost(
            sort: { fields: date, order: DESC }
            limit: 10
        ) {
            edges {
                node {
                    ...PostListFields
                }
            }
        }
    }
`;

export default Index;
