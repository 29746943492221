import React from 'react';

import aviva_64 from '../img/partners-logos-bw/aviva_64.png';
import uniqa_64 from '../img/partners-logos-bw/uniqa_64.png';
import benefia_64 from '../img/partners-logos-bw/benefia_64.png';
import beesafe from '../img/partners-logos-bw/beesafe.png';
import generali_64 from '../img/partners-logos-bw/generali_64.png';
import link4_64 from '../img/partners-logos-bw/link4_64.png';
import mtu_64 from '../img/partners-logos-bw/mtu_64.png';
import proama_64 from '../img/partners-logos-bw/proama_64.png';
import pzu_64 from '../img/partners-logos-bw/pzu_64.png';
import tuz from '../img/partners-logos-bw/tuz.png';
import ycd_64 from '../img/partners-logos-bw/you-can-drive_64.png';
import trasti from '../img/partners-logos-bw/trasti_64.png';

export const SliderLogos: React.FC = () => (
  <>
    <img style={{ height: '64px', width: '65px' }} className="partners-list__img" src={uniqa_64} alt="Axa logo" />
    <img style={{ height: '64px', width: '160px' }} className="partners-list__img" src={benefia_64} alt="Benefia logo" />
    <img style={{ height: '64px', width: '250px' }} className="partners-list__img" src={beesafe} alt="Benefia logo" />
    <img style={{ height: '64px', width: '255px' }} className="partners-list__img" src={generali_64} alt="Generali logo" />
    <img style={{ height: '64px', width: '220px' }} className="partners-list__img" src={link4_64} alt="Link4 logo" />
    <img style={{ height: '64px', width: '149px' }} className="partners-list__img" src={mtu_64} alt="MTU24 logo" />
    <img style={{ height: '64px', width: '160px' }} className="partners-list__img" src={proama_64} alt="Proama logo" />
    <img style={{ height: '64px', width: '65px' }} className="partners-list__img" src={pzu_64} alt="PZU logo" />
    <img style={{ height: '64px', width: '190px' }} className="partners-list__img" src={trasti} alt="Trasti" />
    <img style={{ height: '64px', width: '157px' }} className="partners-list__img" src={tuz} alt="TUZ logo" />
    <img style={{ height: '64px', width: '190px' }} className="partners-list__img" src={ycd_64} alt="You-Can-Drive" />
  </>
);
